<template>
	<div class="team-members">
		<div class="row">
			<div class="col-12">
				<h6 class="oml-h6Title">Integrations</h6>
			</div>
		</div>
		
		<div class="profile_forms col-md-6 mt-4">
			<div class="form-row m-0">
				<div class="form-group col-md-12 pl-0">
					<label for="calendly">{{ $t('Calendly') }} </label>
					<input type="text" class="form-control " v-model="calendlyUrl" id="calendlyUrl" :placeholder="$t('Calendly URL...')"  />
				</div>
			</div>

			<div :class="['form-group']"><!-- has-error -->
				<button type="submit" class="oml-btn" @click="updateCalendly()">
					<i class="fa fa-spinner fa-spin" v-if="Loading"></i> {{ $t('Save') }}
				</button>
			</div>
			
		
		</div>
		
    </div>
</template>

<script>
	export default {
		name: 'integrations',
		data() {
			return {
				calendlyUrl : "",
				Loading		  : false,
			}
		},
		methods: {
           
			updateCalendly() {
				let that = this
				that.Loading = true
				let url   = 'calendly/update'
				let method= 'POST'
                let data = {api_key : this.calendlyUrl}
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					if( response.data.success ) {
						//that.getCalendly()
						this.api_key  		= response.data.settings.api_key;
						this.$store.state.calendly_url = response.data.settings.url;
					}
					that.notifyMe(response.data.message, 'success')
					that.Loading = false
				}).catch(( {response} ) => {
					if( typeof(response) != 'undefined' ) {
						that.notifyMe(response.data.message, 'warn')
					}
					that.Loading = false
				})
				
			},
			notifyMe(message, variant) {
				this.$notify({
					group	: 'foo',
					// title: 'Important message',
					text	: message,
					type	: variant,
					// duration: 500000,
					// speed	: 1000
				});
			},
			
			getCalendly() {
				let that = this
				
				let url   = '/calendly'
				let method= 'GET'
				let data  = {}
				that.globalLoading = true
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					console.log('response',response.data);
					this.calendlyUrl  = response.data.api_key

                    localStorage.setItem("calendly-url",response.data.api_key)

					that.globalLoading = false
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.globalLoading = false
				})
				
			},
			
		},
		
		
		mounted() {
			this.getCalendly()
			this.$store.state.calendly_url = this.calendly_url
		},
		
		
		
	}
</script>

<style lang="scss">
	
	.table-grid {
		thead th {
			font-weight: normal !important;
			font-size: 14px;
		}
	}
	
	.custom-control-inline {
		width: 8em;
	}
	
	[role-tooltip] {
		position:relative;
	}
	[role-tooltip]:before {
		
		position: absolute;
		left: -52px;
		top: -52px;
		font-size: 12px;
		height: 4em;
		background-color: #171717;
		color: #ffffff;
		border-radius: 5px;
		padding: 5px 8px;
		content: attr(content);
		display: none;
		transition: .25s ease-in-out;
		font-weight: 600;
		width: 30em;
		
	}
	[role-tooltip]:after {
		position: absolute;
		right: 30%;
		top: -4px;
		border-top: 7px solid #171717;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		content: "";
		display: none;
		transition:.25s ease-in-out;
	}
	[role-tooltip]:hover:after, [role-tooltip]:hover:before {
		display: block;
		transition:.25s ease-in-out;
	}
	
	.fb-table{
		thead{
			th{
				& > div{
					color: #8c8c8c;
					font-weight: normal;
					font-size: 14px;
					display:flex;
					align-items:center;
					justify-content:space-between;
					
					.fa{
						opacity: 0.66;
					}
				}
			}
		}
		
	}

	/*.profile_forms{
		input{
			width:100%;
			max-width:480px;
			margin:0 auto;
		}

		button{
			min-width:150px;
		}
	}*/
	
</style>
