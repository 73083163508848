<template>
    <div class="profileSmtp-container">
        <div class="profileSmtp-header">
            <h6 class="oml-h6Title">{{$t('services_SMTP')}}
				<i v-b-modal="'addProvider'" class="fa fa-plus-circle cursor-pointer" style="color:#377cff;font-size: 17px;margin-left: 7px;"></i>
			</h6>
			
			<div class="p-desc">
				<p>{{ $t('desc_smtp') }}</p>
			</div>
            <b-modal id="addProvider" size="lg" hide-footer :title="$t('add_provider')" @hidden="hideModal">
                <div class="form-row m-0">
                    <div class="form-group col-md-6 pl-0" style="display:none">
                        <label>{{$t('smtp_provider')}}</label>
                        <select name="lang"  class="form-control " v-model="smtp.providers.selected" @change="changeProvider()">
                            <option v-for="(option,key) in smtp.providers.options" :value="option.value" v-bind:key="key">{{ option.text }}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-6 pl-0" v-if="smtp.providers.selected == 'Other'" style="display:none">
                        <label>{{$t('provider_name')}}</label>
                        <input type="text" class="form-control " v-model="smtp.provider_name" :placeholder="$t('provider_name')" />
                        <small v-if="errors.provider_name" style="color: red;"> * {{ $t('provider_name_required') }} </small>
                    </div>
                    <div class="form-group col-md-6 pl-0" v-if="smtp.providers.selected == 'Other'">
                        <label>{{$t('smtp_server')}}</label>
                        <input type="text" class="form-control " v-model="smtp.host" :placeholder="$t('example')" />
                        <small v-if="errors.smtp_host" style="color: red;"> * {{ $t('smtp_server_required') }} </small>
                    </div>
                    <div class="form-group col-md-6 pl-0" v-if="smtp.providers.selected == 'Other'">
                        <label>{{$t('smtp_port_number')}}</label>
                        <input type="text" class="form-control " v-model="smtp.port" :placeholder="$t('smtp_port_number_here')" />
                        <small v-if="errors.smtp_port" style="color: red;"> * {{ $t('smtp_port_required') }} </small>
                    </div>
                    <div class="form-group col-md-6 pl-0" v-if="smtp.providers.selected == 'Other'">
                        <label>{{$t('Encryption')}}</label>
                        <select name="lang"  class="form-control " v-model="smtp.encryption.selected">
                            <option v-for="(option,key) in smtp.encryption.options" :value="option.value" v-bind:key="key">{{ option.text }}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-6 pl-0"  v-if="(smtp.providers.selected == 'Other') || (smtp.providers.selected == 'Yahoo') || (smtp.providers.selected == 'Gmail')">
                        <label>{{$t('smtp_user_name')}}</label>
                        <input type="text" class="form-control " v-model="smtp.user_name" :placeholder="$t('smtp_user_name')" />
                        <small v-if="errors.smtp_user_name" style="color: red;"> * {{ $t('smtp_user_name_required') }} </small>
                    </div>
                    <div class="form-group col-md-6 pl-0" v-if="(smtp.providers.selected == 'Other') || (smtp.providers.selected == 'Yahoo') || (smtp.providers.selected == 'Gmail')">
                        <label for="">{{$t('smtp_email')}}</label>
                        <input type="text" class="form-control " v-model="smtp.email" :placeholder="$t('smtp_email')" />
                        <small v-if="errors.smtp_email" style="color: red;"> * {{$t('smtp_email_required')}} </small>
                    </div>
                    <div class="form-group col-md-6 pl-0" v-if="(smtp.providers.selected == 'Other')  || (smtp.providers.selected == 'Yahoo') || (smtp.providers.selected == 'Gmail')">
                        <label>{{$t('smtp_password')}} </label>
                        <input type="password" class="form-control " v-model="smtp.password" :placeholder="$t('smtp_password')" />
                        <small v-if="errors.smtp_password" style="color: red;"> * {{$t('smtp_password_required')}} </small>
                    </div>
                    <div :class="[smtp.providers.selected == 'Gmail' || smtp.providers.selected == 'Outlook' ? 'form-group col-md-12 pl-0 d-flex justify-content-end' : 'form-group col-md-6 offset-md-6 pl-0']">
                        <div class="modal-actions">
                            <button class="oml-btn outlined gray-btn mr-3" @click="$bvModal.hide('addProvider')">{{$t('smtp_cancel')}}</button>
                            <button class="oml-btn" @click="save()">{{$t('smtp_save')}}</button>
                        </div>
                    </div>
                </div>
            </b-modal>
            
        </div>
        <div class="profileSmtp-content mt-1">
            <div class="profileSmtp-datatable">
				<data-table :smtp="smtp" :mySmtp="items" :columns="fields"></data-table>
				
				<div class="oml-smtpListing-empty">
					<div v-if="!items.length" class="d-flex align-items-center justify-content-center flex-column">
						<img src="/img/empty.png" alt="">
						<h5>{{$t('Nothing_smtp_yet')}} !</h5>
						<button class="oml-btn" v-b-modal="'addProvider'"><span>+</span> {{ $t('add_smtp_provider') }}</button>
					</div>
				</div>
            </div>
        </div>
    </div>
</template>

<script>
	import dataTable from '@/plugins/md/dataTable/datatable-mysmtp.vue';

    export default {
		components: {
			dataTable
        },
        data() {
            return {
                fields: [],
                items: [],
                selected: [],
                checkedItems: [],
                allSelected: false,
                tableValues: [],
                smtp : {
                    providers: {
                        selected:'Other',
                        options:[
                            { text: 'Gmail', value: 'Gmail' },
                            { text: 'Yahoo', value: 'Yahoo' },
                          //{ text: 'Outlook', value: 'Outlook' },
                            { text: 'Other', value: 'Other' },
                        ],
                    },
                    encryption: {
                        selected:'SSL',
                        options:[
                            { text: 'SSL', value: 'SSL' },
                            { text: 'TLS', value: 'TLS' },
                        ],
                    },
                    provider_name : 'Other',
                    host : '',
                    port : '',
                    status : false,
                    user_name : '',
                    email : '',
                },
				selectedProviderId:'',
				selectedProviderObject:'',
                errors:{},
                loadingAction:'',
            }
        },
        methods: {
            selectAllRows() {
                if(this.allSelected) {
                    this.$refs.selectableTable.selectAllRows()
                }else{
                    this.$refs.selectableTable.clearSelected()
                }
            },
            clearSelected() {
                this.$refs.selectableTable.clearSelected()
            },
            getSmtps() {
                let that   = this;              
                let url    = 'get_smtps';
                let method = 'GET';
                let data   = {};
                that.$store.dispatch( 'apiRequest', {url,method,data}).then((response) => {
                    if(response.data.success){
                        that.items = response.data.smtps;
                    }
                });
            },
            save() {
                let that   = this;
                that.errors = {};
                let url    = 'save_smtp';
                let method = 'POST';
                if (that.smtp.provider_name == '' && that.smtp.providers.selected != 'Gmail' && that.smtp.providers.selected != 'Yahoo') {
                    that.errors['provider_name']  = 'Provider Name Required';
                }
                if(that.smtp.port == '' && that.smtp.providers.selected != 'Gmail' && that.smtp.providers.selected != 'Yahoo') {
                    that.errors['smtp_port'] = 'SMTP Port Required';
                }
                if(that.smtp.host == '' && that.smtp.providers.selected != 'Gmail' && that.smtp.providers.selected != 'Yahoo') {
                    that.errors['smtp_host'] = 'SMTP Server Required';
                }
                if(that.smtp.user_name == '') {
                    that.errors['smtp_user_name'] = 'SMTP User Name Required';
                }
                if(that.smtp.email == '') {
                    that.errors['smtp_email'] = 'SMTP Email Required';
                }
                if(that.smtp.password == '') {
                    that.errors['smtp_password'] = 'SMTP Password Required';
                }
                if (Object.keys(that.errors).length == 0) {
                    let data   = {smtp_provider:that.smtp.providers.selected,smtp_provider_name:that.smtp.provider_name,smtp_host:that.smtp.host,smtp_port:that.smtp.port,smtp_user_name:that.smtp.user_name,smtp_email:that.smtp.email,smtp_password:that.smtp.password,smtp_encryption:that.smtp.encryption.selected};
                    that.$store.dispatch('apiRequest',{url,method,data}).then((response) => {
                        if(response.data.success) {
                            that.notifyMe(response.data.message,'success')
                            that.items = response.data.smtps;
                            that.$bvModal.hide('addProvider');
                        } else {
                            that.notifyMe(response.data.message, 'warn')
                        }
                    });
                }
            },
            hideModal() {
                let that = this;
                that.smtp.providers.selected = 'Other';
                that.smtp.provider_name = 'Other';
                that.smtp.host = '';
                that.smtp.port = '';
                that.smtp.user_name = '';
                that.smtp.email = '';
                that.smtp.password = '';
                that.errors = {};
                that.getSmtps();
            },
            changeProvider() {
                let that = this;
                that.errors = {};
                that.smtp.provider_name = 'Other';
                that.smtp.host = '';
                that.smtp.port = '';
                that.smtp.user_name = '';
                that.smtp.email = '';
                that.smtp.password = '';
                that.smtp.encryption.selected = 'SSL';
            },
            translateCol (colName) {
                let that = this;
                return that.$t(colName);
            }
        },
        mounted() {
            let that   = this;
            that.$i18n.locale = localStorage.getItem('i18n_lang')
            that.fields    =  [
                //{key: 'selected',label: 'Selected',sortable: false},
                {key: 'user_name',label: this.translateCol('smtp_user_name'),sortable: false},
                {key: 'email',label: this.translateCol('smtp_email_listing'),sortable: false},
                {key: 'status',label: this.translateCol('smtp_status_listing'),sortable: false},
                {key: 'action',label: 'Actions',sortable: false}
            ];
            that.getSmtps();
        }
    }
</script>

<style lang="scss">
    .profileSmtp-container{
        width:100%;
        margin:0 auto;
        .profileSmtp-header{

            h3{
                font-size:15px;
                font-weight:600;
                color:#000;
                margin-bottom:0;
            }

            button{
                background-color:#00bbf6;
                border:1px solid #00bbf6;
                border-radius:5px;
                color:#fff;
                font-size:14px;
                font-weight:300;
                display:flex;
                align-items:center;
                padding-left:15px;
                padding-right:15px;

                span{
                    font-size:18px;
                    margin-right:8px;
                }
            }
        }

        .profileSmtp-content{
            .table{
                th{
                    div{
                        color:#b3b3b3 !important;
                        font-size:14px;
                        font-weight:400;
                    }
                }
                
                td{
                    font-size:14px;

                    .btn-group{
                        button{
                            padding:0;
                            line-height:16px;

                            i{
                                padding:0;
                            }
                        }
                    }
                }
            }
        }
    }

    #addProvider, #editProvider{
        .modal-header{
            border-bottom:0px solid transparent;

            h5{
                font-size:16px;
                font-weight:600;
            }
        }

        .form-group{
            label{
                font-size:14px;
                font-weight:500;
            }
        }

        p{
            font-size:14px;
            font-weight:400;
        }

        .modal-actions{
            display:flex;
            align-items:flex-end;
            justify-content: flex-end;
            height:100%;

            button{
                padding:6px 16px;
                min-width:140px;
                font-size:14px;

                &.cancel-btn{
                    background-color:#fff;
                    border:1px solid #8d8d8d;
                    border-radius:5px;
                    color:#8d8d8d;
                }

                &.save-btn{
                    background-color:#06aff3;
                    border:1px solid #06aff3;
                    border-radius:5px;
                    color:#fff;
                }
            }
        }
    }
	
	.oml-smtpListing-empty{
		padding:50px 0;
		text-align:center;
		display:flex;
		align-items:center;
		justify-content:center;

		h5{
			font-weight: 700;
			color: #1d2841;
			font-size:20px;
		}

		p{
			font-weight: 400;
			color: #1d2841;
			font-size:14px;
			max-width:300px;
		}

		button{
			min-width:250px;
			border-radius:8px;
			padding:8px 15px;
			color:#fff;
			display: flex;
			align-items: center;
			justify-content: center;

			span{
				font-size: 24px;
				color: #fff;
				margin-right: 10px;
				line-height: 20px;
			}

		}
	}
</style>