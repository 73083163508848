<template>
    <div id="subscription" class="mt-2">
        <div class="souscription_tabs">
            <div class="row mb-5">
                <div class="col-12">
                    <div class="text-center mb-3">
                        <h3 class="plan-title-size mt-3">
						{{ $t('PayAsYouGrow') }} <i class="fa fa-spinner fa-spin" style="font-size: 26px;" v-if="isPlansLoading"></i>
						</h3>
                        <p class="plan-p-style" v-html="$t('PayPlanDesc')"></p>
						<div class="btn-group" role="group" >
							<button type="button" @click="changeInterval('monthly')" :class="['btn', $store.state.planInterval == 'monthly' ? 'btn-upgrade' : 'btn-outline-upgrade']">
								{{ $t('MonthlyBilling') }}
							</button>
							<button type="button" @click="changeInterval('yearly')" :class="['btn', $store.state.planInterval == 'yearly' ? 'btn-upgrade' : 'btn-outline-upgrade']">
								<!-- (3 months FREE) -->
								{{ $t('AnnualBilling') }}
							</button>
						</div>
                    </div>
					
					<!-- {{ planSelected }} -->
					
                    <!--div class="row">
                        <div class="col-4" v-for="(plan, id) in planSelected" v-bind:key="`plan-${id}`">
                            <content-placeholders class="content-placeholder">
                                <content-placeholders-heading/>
                                <content-placeholders-text :lines="5"/>
                            </content-placeholders>
							{{ plan }}
                        </div>
                    </div-->
					
					<div class="text-center pb-4">
						<div>
							<button v-for="(item, id) in plansAverageList" @click="ChangePlanInterval(item)" v-bind:key="`item-${id}`" 
							:class="['btn mr-2', ListPlans[$store.state.planInterval].label == item.label ? 'btn-upgrade' : 'btn-outline-upgrade']">
								{{ item.label }}
							</button>
						</div>
						<!--div>Sync & Keep <b>{{ ListPlans[$store.state.planInterval].leads }}</b> Leads in Ohmylead</div-->
					</div>
                    
					<div class="row">
                        <template v-for="(plan, Id) in planSelected">
							<div class="col-4" v-if="plan.plan_type + 'ly' == $store.state.planInterval" v-bind:key="`plans-${Id}`">
								<div class="panel panel-default plan">
									<div class="panel-body">
										<div class="row">
											<div class="col-6">
												<h4 class="plan-title">{{ plan.name | capitalize }}</h4>
											</div>
											<div class="col-6 text-right">
												<span class="rounded-pill badge-plan" v-if="plan.plan_id == $auth.user().plan_id || plan.subscribed_to.check == 'true'">
													{{ $t('CurrentPlan') }}
												</span>
											</div>
										</div>
										<div class="plan-price">{{ plan.price }} $ <span>{{ $t('Per') }} {{plan.plan_type}}</span></div>
										
										<button v-if="plan.subscribed_to.check == 'false'" class="btn btn-upgrade btn-block" @click="subscribe(plan)">
											{{ plan.subscribed_to.text }} 
										</button>
										<button v-else class="btn btn-upgrade btn-block" @click="subscribe(plan)" :disabled="plan.plan_id == $auth.user().plan_id || plan.subscribed_to.check == 'true'">
											{{ plan.subscribed_to.text }}
										</button>
										
										<div><b>{{ arrayTitle[plan.name] }}</b></div>
										<!--span class="label label-default plan-leads">Unlimited Leads</span-->
										<ul class="list-unstyled plan-features">
											<li v-for="item in features[plan.name]" v-bind:key="item">
												<span class="fa fa-check"></span> {{ item }}
											</li>
											<!--li><span class="fa fa-check"></span> Unlimited users</li>
											<li><span class="fa fa-check"></span> Email Support</li>
											<li><span class="fa fa-check"></span> Live Support</li-->
										</ul>
										<!--div v-if="plan.plan_id == $auth.user().plan_id" class="text-center text-success formClass">
											<span class="fa fa-check-square-o"></span>
											Currently using for 
											<a href="#" class="btn btn-primary btn-block divinterval" @click.prevent="subscribe(plan)">
												Change to {{ plan.plan_type | changeTo }}
											</a>
										</div>
										<a v-else href="#" class="btn btn-primary btn-block" @click.prevent="subscribe(plan)">
											{{ plan.subscribed_to.text }}
										</a-->
										
									</div>
								</div>
							</div>
                        </template>
                        <div class="col-4 d-none">
                            <div class="panel panel-default plan">
                                <div class="panel-body">
                                    <h3 class="plan-title">Entreprise</h3>
                                    <div class="plan-price">Let's Talk</div>
                                    <span class="label label-default plan-leads">Unlimited Leads</span>
                                    <ul class="list-unstyled plan-features">
                                        <li><span class="fa fa-check"></span> All features</li>
                                        <li><span class="fa fa-check"></span> Unlimited users</li>
                                        <li><span class="fa fa-check"></span> Email Support</li>
                                        <li><span class="fa fa-check"></span> Priority Live Support</li>
                                    </ul>
                                    <a  class="btn btn-primary btn-block" @click.prevent="contact" href="javascript:void(0)">{{$t('ContactUs')}}</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <b-modal v-model="modalOpen" size="md" :backdrop="false" :footer="false" class="integrations-settings-modal">
						<span slot="title">{{$t('ContactUs')}}</span>
                        <div class="globalContent">
                            <!-- Default form login -->
                            <form class="text-center border border-light p-5" action="#!">
                                <div class="form-group">
                                    <input type="email" id="defaultLoginName" name="email" class="form-control mb-4" placeholder="E-mail to contact" v-model="email">
                                </div>
                                <div class="form-group">
                                    <textarea id="defaultLoginFormEmail" name="subject" rows="5" class="form-control mb-4" placeholder="" v-model="subject"></textarea>
                                </div>
                                <button class="btn btn-primary btn-block" type="submit" @click.prevent="submit">Send</button>
                            </form>
                            <!-- Default form login -->
                        </div>
                    </b-modal>
                    <!-- <price-calculator></price-calculator> -->
					
					<b-modal id="change-plan" centered hide-footer title="Confirmation"> <!-- size="sm" -->
						<div class="row">
							<div class="col-12 mb-1" style=" color: #000; font-size: 14px; ">
								<p class="m-0">{{ $t('QuestionContinue') }}</p>
								<span>{{ $t('Confirm') }} </span><b>{{ selectedPlan.name }}</b>
							</div>
						</div>
						<div class="row">
							<div class="col-12 text-right">
								<b-button size="sm" variant="success" @click="changePlan()">
									<i class="fa fa-spinner fa-spin" v-if="plansLoaded"></i> {{ $t('Confirm') }}
								</b-button>
							</div>
						</div>
					</b-modal>
					
                </div>
            </div>
            <center class="pt-3 d-none"><h3>All Features included in all plans</h3></center>
            <div class="row d-none" style="padding-top: 2rem;">
                <div class="col-10 mx-auto">
					<div class="row">
						<div class="col-4">
							<h4>Lead Sources</h4>
							<hr>
							<ul class="list-unstyled plan-features features">
								<li><span class="fa fa-check"></span> Landing & WebForms builder</li>
								<li><span class="fa fa-check"></span> Zapier Connexion</li>
								<li><span class="fa fa-check"></span> Integromat Connexion</li>
								<li><span class="fa fa-check"></span> Custom Webhooks</li>
							</ul>
						</div>
						<div class="col-4">
							<h4>Lead Management</h4>
							<hr>
							<ul class="list-unstyled plan-features features">
								<li><span class="fa fa-check"></span> Automatic Email & Phone verification</li>
								<li><span class="fa fa-check"></span> Smart Lead Scoring</li>
								<li><span class="fa fa-check"></span> Lead Dispatch</li>
								<li><span class="fa fa-check"></span> Real time lead alerts</li>
								<li><span class="fa fa-check"></span> Welcome email</li>
								<li><span class="fa fa-check"></span> Analytics</li>
							</ul>
						</div>
						<div class="col-4">
							<h4>Integrations</h4>
							<hr>
							<ul class="list-unstyled plan-features features">
								<li><span class="fa fa-check"></span> Unlimited CRM integrations</li>
								<li><span class="fa fa-check"></span> Unlimited ESP integrations</li>
								<li><span class="fa fa-check"></span> Call software</li>
								<li><span class="fa fa-check"></span> SMS software</li>
								<li><span class="fa fa-check"></span> Lead enrichment integration</li>
							</ul>
						</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "PlanList",
        data() {
            return {
                isPlansLoading	: false,
                plansLoaded		: false,
                ListPlans		: {
					"yearly"  : { id: 0, label: '500', leads: 500, arrayPlans: [] },
					"monthly" : { id: 0, label: '500', leads: 500, arrayPlans: [] }
				},
                selectedPlan	: {},
                // planInterval	: 'monthly',
                divinterval		: false,
                oldinterval		: '',
                interval		: null,
                modalOpen		: false,
                email			: '',
                subject			: '',
                current_plan	: false,
				
				plansAverageList : [
					{ id: 0, label: '500', 	leads: 500    , arrayPlans: [] },
					{ id: 1, label: '1000', leads: 1000   , arrayPlans: [] },
					{ id: 1, label: '2500', leads: 2500   , arrayPlans: [] },
					{ id: 2, label: '5000', leads: 5000   , arrayPlans: [] },
					{ id: 3, label: '10k', 	leads: 10000  , arrayPlans: [] },
					{ id: 4, label: '25k', 	leads: 25000  , arrayPlans: [] },
					{ id: 5, label: '50k', 	leads: 50000  , arrayPlans: [] },
					{ id: 6, label: '100k', leads: 100000 , arrayPlans: [] },
					// { id: 7, label: '500K', leads: 500000 , arrayPlans: [] }
				],
				
				features	: {
					'Solo'  : ['Unlimited facebook forms', 'Unlimited Custom Fields', 'Email & Phone Validation', 'Unlimited Lead Alert', 'Unlimited Welcome Email', 'Excel Import & export'],
					'Agency'  : ['Unlimited users', 'Unlimited leads / day', 'Premium Support Package'],
					'Whitelabel': ['Unlimited clients', 'Custom Integrations', 'Coaching Onboarding Sessions', 'Dedicated Specialist', 'Periodic Account Review']
				},
				arrayTitle	: {
					'Solo'  : 'What’s included:',
					'Agency'  : 'Everything in SOLO, plus:',
					'Whitelabel': 'Everything in TEAM, plus:'
				}
				
            }
        },
		computed: {
			planSelected: function() {
				let that = this
				let plans = that.ListPlans[that.$store.state.planInterval].arrayPlans
				return plans;
			}
		},
        mounted() {
            this.loadPlans()
        },
        components: {
			
        },
        methods: {
            notifyMe(message, variant){
				this.$notify({
					group	: 'foo',
					// title: 'Important message',
					text	: message,
					type	: variant,
					// duration: 5000,
					// speed	: 1000
				});
			},
			
			changePlan() {
				let that = this
				that.plansLoaded = true
				
				let url   = '/plan/updatesubsscription'
				let method= 'POST'
				let data  = { plan_id:that.selectedPlan.plan_id, subscription_id:that.selectedPlan.subscription_id }
				// console.log(data)
				that.$store.dispatch('apiRequest', { url, method, data } ).then((/* response */) => {
					that.loadPlans()
					that.plansLoaded = false
					that.notifyMe(this.$t('ChangesSaved'), 'success')
					that.$bvModal.hide('change-plan');
					
					location.reload()
					
				}).catch(( {response} ) => {
					that.notifyMe(response.data.message, 'warn')
					that.plansLoaded = false
					that.$bvModal.hide('change-plan');
				})
				
			},
            ChangePlanInterval(interval) {
				let that = this
				that.ListPlans[that.$store.state.planInterval].id 		 = interval.id
				that.ListPlans[that.$store.state.planInterval].label 	 = interval.label
				that.ListPlans[that.$store.state.planInterval].leads 	 = interval.leads
				that.ListPlans[that.$store.state.planInterval].arrayPlans = interval.arrayPlans
			},
			changeInterval(interval) {
				this.$store.state.planInterval = interval
				/* 
				if(this.oldinterval==interval) {
					this.divinterval=false;
				}
                else {
					this.interval='Change to '+interval+'ly';
					this.divinterval=true;
				} */
			},
            loadPlans() {
                
				let that = this
				let url   = '/plan/list'
				let method= 'POST'
				let data  = {} // provider:"show plan"
				
				that.isPlansLoading = true
				
				that.$store.dispatch('apiRequest', { url, method, data } ).then((response) => {
					that.ListPlans = { "yearly"  : { id: 0, label: '500', leads: 500, arrayPlans: [] }, "monthly" : { id: 0, label: '500', leads: 500, arrayPlans: [] } }
					
					that.plansAverageList = [
						{ id: 0, label: '500', 	leads: 500    , arrayPlans: [] },
						{ id: 1, label: '1000', leads: 1000   , arrayPlans: [] },
						{ id: 1, label: '2500', leads: 2500   , arrayPlans: [] },
						{ id: 2, label: '5000', leads: 5000   , arrayPlans: [] },
						{ id: 3, label: '10k', 	leads: 10000  , arrayPlans: [] },
						{ id: 4, label: '25k', 	leads: 25000  , arrayPlans: [] },
						{ id: 5, label: '50k', 	leads: 50000  , arrayPlans: [] },
						{ id: 6, label: '100k', leads: 100000 , arrayPlans: [] },
						// { id: 7, label: '500K', leads: 500000 , arrayPlans: [] }
					]
					
					let plans = response.data.plans, plansAverageList = that.plansAverageList
					that.current_plan = response.data.current_plan
					plans.forEach( function( plan ) {
						
						plansAverageList.forEach( function (item, id) {
							
							if ( item.leads == plan.total_leads ) {
								that.plansAverageList[id].arrayPlans.push(plan)
							}
							if( plan.total_leads == item.leads && item.leads == 500 ) {
								that.ListPlans[plan.plan_type + 'ly'].arrayPlans.push(plan)
							}
						} )
					} )
					// console.log( that.plansAverageList )
					that.isPlansLoading = false
				}).catch(( {response} ) => {
					// console.log(response)
					that.notifyMe(response.data.message, 'warn')
				})
				
            },
            price(plan) {
                return this.$store.state.planInterval === 'monthly'
                    ? plan.monthly_price
                    : parseInt(plan.annual_price) / 12
            },
            subscribe(plan) {
				let that 	= this
				let element = plan.name
				let email   = that.$auth.user().email
				
				if ( that.current_plan ) {
					that.selectedPlan = plan
					that.$bvModal.show('change-plan');
				} else {
					
					if( element == process.env.VUE_APP_PLAN_SOLO ) {
						window.Paddle.Checkout.open({ 
							product: plan.product_id, passthrough:email, email:email, allowQuantity:false, disableLogout:true,
							successCallback: that.successCallback, closeCallback: that.closeCallback
						})
					} else if( element == process.env.VUE_APP_PLAN_AGENCY ) {
						window.Paddle.Checkout.open({
							product: plan.product_id, passthrough:email, email:email, allowQuantity:false, disableLogout:true,
							successCallback: that.successCallback, closeCallback: that.closeCallback
						})
					} else if( element == process.env.VUE_APP_PLAN_FULL ) {
						window.Paddle.Checkout.open({
							product: plan.product_id, passthrough:email, email:email, allowQuantity:false, disableLogout:true,
							successCallback: that.successCallback, closeCallback: that.closeCallback
						})
					}
				}
            },
			successCallback() {
				window.location = '/payment/success';
				window.tap('create', '20997-e0c1ad', { integration: "javascript" });
				window.tap('conversion');
			},
			closeCallback() {
				window.location = '/payment/error';
				window.tap('create', '20997-e0c1ad', { integration: "javascript" });
				window.tap('conversion');
			},
        },
		
		filters: {
			changeTo: function(element) {
				let title = element == 'month' ? 'yearly' : 'monthly'
				return title
			},
			
			capitalize: function (str) {
				return str.charAt(0).toUpperCase() + str.slice(1)
			},
		},
		
    }
</script>

<style scoped lang="scss">
	.badge-plan {
		color: #28a745;
		background-color: #ace2b8;
		padding: 8px;
		font-size: 12px;
		font-weight: 500;
	}
	.plan-title-size {
		line-height: 50px;
		font-weight: 700;
		font-size: 35px;
	}
	.plan-p-style {
		font-size: 14px;
	}
	.currently-using {
        display: block;
        width: 100%;
        height: 60px;
        line-height: 60px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 900;
        color: #010000;
        border-radius: 4px;
        background: #f5f5f5;
    }
	
    .plan {
        -webkit-box-shadow: 0 3px 8px 0px rgba(0, 0, 0, 0.06), 0 0px 2px 0px rgba(0, 0, 0, 0.08);
        box-shadow: 0 3px 8px 0px rgba(0, 0, 0, 0.06), 0 0px 2px 0px rgba(0, 0, 0, 0.08);
        border-radius: 5px !important;
		height: 28em;
    }
	
    /* .plan:hover {
        background-color: #f0f8ff;
    } */

    .plan:hover .plan-leads {
        background-color: #FFFFFF;
    }

    .plan-title {
        color: #000;
		text-transform: uppercase;
    }

    .plan-price {
        color: #00BBF6;
        font-weight: 600;
        font-size: 2rem;
    }

    .plan-price span {
        font-size: 1rem;
    }

    .plan-leads {
        background: #f0f8ff;
        margin: 1rem 0;
        display: block;
        width: fit-content;
        padding: 10px 15px;
        font-size: 1rem;
        color: #377dff;
    }

    .plan-features {
        font-size: 14px;
        line-height: 34px;
		/* min-height: 17em; */
    }

    .plan-features span {
        font-size: 12px;
        vertical-align: 2px;
        color: #6ba0ff;
    }

    .plan .panel-body {
        padding: 26px 22px;
    }

    .plan .btn {
        padding: .7rem;
        margin-bottom: 1rem;
        font-weight: 600;
        font-size: 1.1rem;
        margin-top: 1rem;
    }

    .text-gray {
        color: #dedede;
    }

    .content-placeholder {
        margin-top: 50px;
    }
	
    .vue-content-placeholders-text__line {
        height: 20px;
    }

    .toggle, .toggler {
        cursor: pointer;
    }

    .page-title {
        padding-top: 20px;
        margin-bottom: 3rem;

        p {
            font-size: 16px !important;
            line-height: 2rem !important;
        }

        h1 {
            font-weight: 600;
            color: #000;
            margin-bottom: 0px;
        }
        main > .container-lg .example{
            min-height: 233px !important;
        }
        .example.strip-form button {
            margin: 13px 15px 0;
        }
    }
</style>
